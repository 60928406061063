import { Pipe, PipeTransform } from '@angular/core';
import { KurzUnitFormattedPriceOptions, KurzUnitService } from '../../services/kurz-unit.service';

@Pipe({
  name: 'kurzPrice'
})
export class KurzPricePipe implements PipeTransform {

  constructor(private kurzUnitService: KurzUnitService) {}

  transform(value: string | number, explicitCurrencyIso: string): string {

    let num: number;

    switch(typeof value) {
      case 'number': num = value as number; break;
      case 'string': num = parseFloat(value); break;
      default: num = value as number;
    }

    const options: KurzUnitFormattedPriceOptions = {
      explicitCurrency: (explicitCurrencyIso || void 0)
    };

    const res = this.kurzUnitService.getFormattedPrice(num, options);

    return res;
  }

}
